import React from "react"
import HelpCenter from "../../../images/help-center.png"
import "./styles.css"
const DocsHelpCenterSection = () => {
  return (
    <section className="help-center-section">
      <div className="container">
        <div className="help-center__content">
          <div className="help-center__text">
            <h1>
              <span className="wt-text wt-text--blue">Help</span> Center
            </h1>
            <h3>
              We cover all stages of cyber attacks with the help of our rich
              modular functionality
            </h3>
          </div>
          <div className="help-center__image">
            <img src={HelpCenter} alt="help center" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default DocsHelpCenterSection
