import React from "react"
import Arrow from "../../../images/arrow.svg"
import "./styles.css"
import { Link } from "gatsby"
const DocsItemsSection = () => {
  return (
    <section className="items-section">
      <div className="container">
        <div className="items-section__content">
          <a
            href="https://docs.wtotem.com/about-webtotem"
            target="_blank"
            rel="noopener"
            className="items-section__item"
          >
            <div className="items-section--item__information">
              <div className="item-section--item__title">Our Features</div>
              <div className="item-section--item__description">
                Discover why you’ll love WebTotem! The section covers general
                information about our features.
              </div>
            </div>
            <div className="items-section--item__arrow">
              <img src={Arrow} alt="" />
            </div>
          </a>
          <a
            href="https://docs.wtotem.com/firewall"
            target="_blank"
            rel="noopener"
            className="items-section__item"
          >
            <div className="items-section--item__information">
              <div className="item-section--item__title">Dashboard</div>
              <div className="item-section--item__description">
                The section helps to understand the dashboard and take measures
                on time.
              </div>
            </div>
            <div className="items-section--item__arrow">
              <img src={Arrow} alt="" />
            </div>
          </a>
          <a
            href="https://docs.wtotem.com/registration"
            target="_blank"
            rel="noopener"
            className="items-section__item"
          >
            <div className="items-section--item__information">
              <div className="item-section--item__title">Instructions</div>
              <div className="item-section--item__description">
                The section gives you full information on how to install and
                work with our platform.
              </div>
            </div>
            <div className="items-section--item__arrow">
              <img src={Arrow} alt="" />
            </div>
          </a>
          <a
            href="https://docs.wtotem.com/plans"
            target="_blank"
            rel="noopener"
            className="items-section__item"
          >
            <div className="items-section--item__information">
              <div className="item-section--item__title">FAQ</div>
              <div className="item-section--item__description">
                Find out if someone has already asked your question.
              </div>
            </div>
            <div className="items-section--item__arrow">
              <img src={Arrow} alt="" />
            </div>
          </a>
        </div>
      </div>
    </section>
  )
}

export default DocsItemsSection
