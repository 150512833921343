import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../sections/header"
import Footer from "../../sections/footer"
import DocsHelpCenterSection from "../../sections/docs/help-center"
import DocsItemsSection from "../../sections/docs/items"

const DocsPage = () => {
  return (
    <Layout>
      <SEO title="Help Center - We cover all stages of cyber attacks with the help of our rich modular functionality" />
      <Header />
      <DocsHelpCenterSection />
      <DocsItemsSection />
      <Footer />
    </Layout>
  )
}

export default DocsPage
